function cargaAnimacion(id, valor, animacion) {
  animacion.op = valor + 1;
  var params = {
    container: document.getElementById(id),
    renderer: 'svg',
    loop: false,
    autoplay: true,
    animationData: animacion
  };
  var anim;
  anim = lottie.loadAnimation(params);
}


function cargaVelocimetro(json) {
  document.getElementById(json.id).innerHTML = '<div class="velocimetro flexFont"><div class="tipo "><a href="' + json.tituloLink + '" title="' + json.titulo + '">' + json.titulo + '</a></div><div class="icono"><img src="' + json.tituloImg + '" alt="' + json.titulo + '"></div><div class="etiq1 etiq "><a href="' + json.azulLink + '">' + json.azulText + '</a></div><div class="etiq2 etiq "><a href="' + json.rojoLink + '">' + json.rojoText + '</a></div><div class="link1 link"><a href="' + json.azulLink + '">&nbsp;</a></div><div class="link2 link"><a href="' + json.rojoLink + '">&nbsp;</a></div><div id="' + json.id + 'vlm"></div>  ';
  cargaAnimacion(json.id + 'vlm', json.valor, animaVelocimetro);
}



function cargaCuatroBarras(json) {
    document.getElementById(json.id).innerHTML = '<div class="grafico_barra1 flexFont"><img src="assets/img/grafico_barras1-base.svg">    <div class="etiq1 etiq"><a href="' + json.aUrl + '">' + json.aText + '</a></div><div class="etiq2 etiq"><a href="' + json.bUrl + '">' + json.bText + '</a></div><div class="etiq3 etiq"><a href="' + json.cUrl + '">' + json.cText + '</a></div><div class="etiq4 etiq"><a href="' + json.dUrl + '">' + json.dText + '</a></div><div class="leyenda_verde etiq"><a href="' + json.verdeLink + '">' + json.verdeText + '</a></div><div class="leyenda_rojo etiq"><a href="' + json.rojoLink + '">' + json.rojoText + '</a></div><div id = "' + json.id + '1" class = "barra1" > </div>  <div id="' + json.id + '2" class="barra2" ></div > <div id = "' + json.id + '3" class = "barra3" class = "barra3" > </div> <div id="' + json.id + '4" class="barra4"></div > ';
    cargaAnimacion(json.id + '1', json.a, animaBARRA);
    cargaAnimacion(json.id + '2', json.b, animaBARRA);
    cargaAnimacion(json.id + '3', json.c, animaBARRA);
    cargaAnimacion(json.id + '4', json.d, animaBARRA);
}

function cargaBarraHorizontal3X3(json) {

  //let json= JSON.parse(jsonC);
  ///let json= jsonC;

  document.getElementById("" + json.id + "").innerHTML = ' <div class="grafico_cumplimiento flexFont"><div class="grupos"><div class="grupo"> <div class="dia">' + json.grupo1 + '</div><div class="barra_hori1"> <div id="' + json.id + 'grupo1red"> </div><div id="' + json.id + 'grupo1green"></div><div id="' + json.id + 'grupo1yellow"></div></div></div><div class="grupo"> <div class="dia">' + json.grupo2 + '</div><div class="barra_hori1"> <div id="' + json.id + 'grupo2red"> </div><div id="' + json.id + 'grupo2green"></div><div id="' + json.id + 'grupo2yellow"></div></div></div><div class="grupo"> <div class="dia">' + json.grupo3 + '</div><div class="barra_hori1"> <div id="' + json.id + 'grupo3red"> </div><div id="' + json.id + 'grupo3green"></div><div id="' + json.id + 'grupo3yellow"></div></div></div></div>  <div class="leyenda_rojo leyenda etiq" style="white-space: nowrap;"><a href="' + json.rojoLink + '">' + json.rojoText + '</a></div><div class="leyenda_amarillo leyenda etiq" style="white-space: nowrap;"><a href="' + json.amarilloLink + '">' + json.amarilloText + '</a></div><div class="leyenda_verde leyenda etiq" style="white-space: nowrap;"><a href="' + json.verdeLink + '">' + json.verdeText + '</a></div></div>';

  cargaAnimacion(json.id + 'grupo1red', json.grupo1red, barraHorizontalRojo);
  cargaAnimacion(json.id + 'grupo1green', json.grupo1green, barraHorizontalVerde);
  cargaAnimacion(json.id + 'grupo1yellow', json.grupo1yellow, barraHorizontalAmarilla);
  cargaAnimacion(json.id + 'grupo2red', json.grupo2red, barraHorizontalRojo);
  cargaAnimacion(json.id + 'grupo2green', json.grupo2green, barraHorizontalVerde);
  cargaAnimacion(json.id + 'grupo2yellow', json.grupo2yellow, barraHorizontalAmarilla);
  cargaAnimacion(json.id + 'grupo3red', json.grupo3red, barraHorizontalRojo);
  cargaAnimacion(json.id + 'grupo3green', json.grupo3green, barraHorizontalVerde);
  cargaAnimacion(json.id + 'grupo3yellow', json.grupo3yellow, barraHorizontalAmarilla);

}

function cargaPick(json, animacion) {

    animacion.op = json.principal + 1;

    animacion.layers[18].t.d.k[0].s.t = json.verde;
    animacion.layers[17].t.d.k[0].s.t = json.amarillo;
    animacion.layers[16].t.d.k[0].s.t = json.rojo;
    animacion.layers[15].t.d.k[0].s.t = json.morado;
    animacion.layers[8].t.d.k[0].s.t = json.circuloIzqValor;
    animacion.layers[9].t.d.k[0].s.t = json.circuloDerValor;
    animacion.layers[7].t.d.k[0].s.t = json.ovaloIzqValor;
    animacion.layers[6].t.d.k[0].s.t = json.ovaloCenValor;
    animacion.layers[5].t.d.k[0].s.t = json.ovaloDerValor;
    animacion.layers[2].t.d.k[0].s.t = json.textAmarillo;
    animacion.layers[1].t.d.k[0].s.t = json.textRojo;
    animacion.layers[0].t.d.k[0].s.t = json.textMorado;

    //document.getElementById(json.id).className= json.tipo;

    document.getElementById("" + json.id + "").innerHTML = '<div class="dias flexFont"><div class="tipo ' + json.tituloColor + '"><a (click)="' + json.tituloLink + '" title="' + json.titulo + '" >' + json.titulo + '</a></div><div class="icono"><img src="' + json.tituloImg + '" alt="Ingresadas"></div><div class="etiq1 etiq_a">' + json.circuloIzqLabel + '</div><div class="etiq2 etiq_a"><a href="' + json.circuloDerUrl + '">' + json.circuloDerLabel + '</a></div><div class="etiq3 etiq_b"><a href="' + json.ovaloIzqUrl + '">' + json.ovaloIzqLabel + '</a></div><div class="etiq4 etiq_b"><a href="' + json.ovaloCenUrl + '">' + json.ovaloCenLabel + '</a></div><div class="etiq5 etiq_b">' + json.ovaloDerLabel + '</div><div id="' + json.id + 'cpick" class="' + json.tipo + '"> </div><div class="link1 link"><a href="' + json.link1 + '">&nbsp;</a></div><div class="link2 link"><a href="' + json.link2 + '">&nbsp;</a></div><div class="link3 link"><a href="' + json.link3 + '">&nbsp;</a></div><div class="link4 link"><a href="' + json.link4 + '">&nbsp;</a></div><div class="link5 link"><a href="' + json.link5 + '">&nbsp;</a></div><div class="link6 link"><a href="' + json.link6 + '">&nbsp;</a></div><div class="link7 link"><a href="' + json.link7 + '">&nbsp;</a></div><div class="link8 link"><a href="' + json.link8 + '">&nbsp;</a></div><div class="link9 link"><a href="' + json.link9 + '">&nbsp;</a></div></div> ';


    var params = {
        container: document.getElementById(json.id + 'cpick'),
        renderer: 'svg',
        loop: false,
        autoplay: true,
        animationData: animacion
    };

    var anim;
    anim = lottie.loadAnimation(params);

}

function cargaIngresada(json, animacion) {

    animacion.op = 101;
    animacion.layers[7].ef[0].ef[0].v.k[0].e[0] = json.principal;
    animacion.layers[2].ef[0].ef[0].v.k[0].e[0] = json.circulo1valor;
    animacion.layers[1].ef[0].ef[0].v.k[0].e[0] = json.circulo2valor;
    animacion.layers[0].ef[0].ef[0].v.k[0].e[0] = json.circulo3valor;
    animacion.layers[8].ef[0].ef[0].v.k[0].e[0] = json.circulo1valor + json.circulo2valor + json.circulo3valor;
    animacion.layers[3].t.d.k[0].s.t = json.tope;

    document.getElementById("" + json.id + "").innerHTML = ' <div class="ingresadas flexFont"> <div class="tipo"><a (click)="' + json.imgUrl + '" title="' + json.imgText + '">' + json.imgText + '</a></div><div class="icono"><img src="' + json.imgDerecha + '" alt="Ingresadas"></div><div class="etiq1 etiq "><a onclick=" llamada( '+"'"+json.circulo1url+"'"+' )">' + json.circulo1text + '</a></div> <div class="etiq2 etiq "><a href="' + json.circulo2url + '"> ' + json.circulo2text + '</a></div><div class="etiq3 etiq "><a href="' + json.circulo3url + '">' + json.circulo3text + '</a></div>    <div class="link1 link"><a href="' + json.circulo1url + '">&nbsp;</a></div><div class="link2 link"><a href="' + json.circulo2url + '">&nbsp;</a></div><div class="link3 link"><a href="' + json.circulo3url + '">&nbsp;</a></div> <div id="' + json.id + 'contenedor"> </div></div>  ';

    var params = {
        container: document.getElementById(json.id + 'contenedor'),
        renderer: 'svg',
        loop: false,
        autoplay: true,
        animationData: animacion
    };

    var anim;
    anim = lottie.loadAnimation(params);

}

function cargaIngresadaX5(json, animacion) {

  animacion.op = 101;
  animacion.layers[11].ef[0].ef[0].v.k[0].e[0] = json.principal;
  animacion.layers[0].ef[0].ef[0].v.k[0].e[0] = json.circulo1valor;
  animacion.layers[2].ef[0].ef[0].v.k[0].e[0] = json.circulo2valor;
  animacion.layers[4].ef[0].ef[0].v.k[0].e[0] = json.circulo3valor;
  animacion.layers[6].ef[0].ef[0].v.k[0].e[0] = json.circulo4valor;
  animacion.layers[8].ef[0].ef[0].v.k[0].e[0] = json.circulo5valor;
  animacion.layers[12].ef[0].ef[0].v.k[0].e[0] = json.circulo1valor + json.circulo2valor + json.circulo3valor + json.circulo4valor + json.circulo5valor;
  animacion.layers[10].t.d.k[0].s.t = json.tope;

  document.getElementById("" + json.id + "").innerHTML = ' <div class="ingresadas flexFont"> <div class="tipo"><a (click)="' + json.imgUrl + '" title="' + json.imgText + '">' + json.imgText + '</a></div><div class="icono"><img src="' + json.imgDerecha + '" alt="Ingresadas"></div><div class="etiq1 etiq "><a onclick=" llamada( '+"'"+json.circulo1url+"'"+' )">' + json.circulo1text + '</a></div> <div class="etiq2 etiq "><a href="' + json.circulo2url + '"> ' + json.circulo2text + '</a></div><div class="etiq3 etiq "><a href="' + json.circulo3url + '">' + json.circulo3text + '</a></div> <div class="etiq4 etiq "><a href="' + json.circulo4url + '"> ' + json.circulo4text + '</a></div> <div class="etiq5 etiq "><a href="' + json.circulo5url + '"> ' + json.circulo5text + '</a></div>   <div class="link1 link"><a href="' + json.circulo1url + '">&nbsp;</a></div><div class="link2 link"><a href="' + json.circulo2url + '">&nbsp;</a></div><div class="link3 link"><a href="' + json.circulo3url + '">&nbsp;</a></div> <div class="link4 link"><a href="' + json.circulo4url + '">&nbsp;</a></div> <div class="link5 link"><a href="' + json.circulo5url + '">&nbsp;</a></div> <div id="' + json.id + 'contenedor"> </div></div>  ';

  var params = {
    container: document.getElementById(json.id + 'contenedor'),
    renderer: 'svg',
    loop: false,
    autoplay: true,
    animationData: animacion
  };

  var anim;
  anim = lottie.loadAnimation(params);

}

function llamada( url ) {
  document.getElementById("prueba").value = url;
 /* $('#prueba').focus();

  var event = jQuery.Event('keypress');
  event.which = 75;
  event.keyCode = 75; //keycode to trigger this for simulating enter
  jQuery(this).trigger(event);*/

 // $('#elClick').click();
}
